
import { defineComponent, PropType } from 'vue';
import Quote from "@/types/Quote";

export default defineComponent({
  name: 'QuoteContent',
  props: {
    quoteText: {
      required: true,
      type: String as PropType<Quote['text']>
    },
  },
  setup() {

    return {}
  }
});
