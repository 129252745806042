
import {defineComponent, onMounted, ref} from 'vue';
import useApi from "@/composables/useApi";
import Quote from "@/types/Quote";
import QuoteContent from "@/components/QuoteContent.vue";
import RandomQuoteButton from "@/components/RandomQuoteButton.vue";

export default defineComponent({
  name: 'AuthorQuotes',
  components: {RandomQuoteButton, QuoteContent},
  props: {
    author: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { getRandomsQuotesFromAuthor } = useApi()
    const quotes = ref<Quote[]>()

    const loadQuotes = async () => {
      quotes.value = await getRandomsQuotesFromAuthor(3, props.author)
    }

    onMounted(() => loadQuotes())

    return { quotes }
  }
});
