
import { defineComponent, PropType } from 'vue';
import { useRouter } from "vue-router";
import Quote from "@/types/Quote";

export default defineComponent({
  name: 'QuoteDetails',
  props: {
    quoteAutor: {
      required: true,
      type: String as PropType<Quote['text']>
    },
    quoteGenre: {
      required: true,
      type: String as PropType<Quote['genre']>
    }
  },
  setup(props) {
    const router = useRouter()

    const redirectUserToAuthorPage = () => {
      router.push({
        name: 'AuthorQuotes',
        params: { author: props.quoteAutor}
      })
    }

    return { redirectUserToAuthorPage }
  }
});
