import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = {
  key: 0,
  class: "quote-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RandomQuoteButton = _resolveComponent("RandomQuoteButton")!
  const _component_QuoteContent = _resolveComponent("QuoteContent")!
  const _component_QuoteDetails = _resolveComponent("QuoteDetails")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_RandomQuoteButton, { onHandleGetQuote: _ctx.loadQuote }, null, 8, ["onHandleGetQuote"]),
    (_ctx.quote)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_QuoteContent, {
            class: "single-quote",
            "quote-text": _ctx.quote?.text
          }, null, 8, ["quote-text"]),
          _createVNode(_component_QuoteDetails, {
            "quote-autor": _ctx.quote?.author,
            "quote-genre": _ctx.quote?.genre
          }, null, 8, ["quote-autor", "quote-genre"])
        ]))
      : _createCommentVNode("", true)
  ]))
}