
import { defineComponent } from 'vue';
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: 'RandomQuoteButton',
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()

    const handleClick = () => {
      if ( route.name === 'Home' ) {
        context.emit('handleGetQuote')
      } else if (route.name === 'AuthorQuotes') {
        router.push({
          name: 'Home'
        })
      }
    }

    return { handleClick }
  }
});
