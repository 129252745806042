
import { defineComponent, onMounted, ref } from 'vue';
import useApi from "@/composables/useApi";
import Quote from "@/types/Quote";

import QuoteDetails from "@/components/QuoteDetails.vue";
import QuoteContent from "@/components/QuoteContent.vue";
import RandomQuoteButton from "@/components/RandomQuoteButton.vue";

export default defineComponent({
  name: 'Home',
  components: {RandomQuoteButton, QuoteContent, QuoteDetails },
  setup() {
    const { getRandomQuote } = useApi()
    const quote = ref<Quote>()

    const loadQuote = async () => {
      quote.value = await getRandomQuote()
    }

    onMounted(() => loadQuote())

    return { quote, loadQuote }
  }
});
